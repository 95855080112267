.loader {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&:after {
		content: "";
		width: 50px;
		height: 50px;
		border: 10px solid #dddddd;
		border-top-color: #9e5700;
		border-radius: 50%;
		animation: loading 1s ease infinite;
	}
}

@keyframes loading {
	to {
		transform: rotate(1turn);
	}
}
