$background_color: #2d2d38;
$content_text_color: #a1aabb;
$header_bg_color: #edae60;
$header_text_color: #fffefd;
$border_color: #383944;
$body_background: #20202b;
$item_background: #2a2a35;
$container_background: #22222b;

$font-weights: (
	regular: 300,
	medium: 500,
	semi-bold: 600,
	bold: 700,
	extra-bold: 900,
);
