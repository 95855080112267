* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

html {
	font-size: 100%;
}

img {
	max-width: 100%;
	vertical-align: middle;
	border: 0;
}

button {
	background: transparent;
	border: 0;
	outline: 0;

	&:active {
		outline: 0;
	}
}
